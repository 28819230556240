














import Vue from 'vue'
import CardTitle from '@/components/CardTitle.vue'
import TenderService from '@/services/tender'
import { TenderWithOffersDTO } from '@/services/tender/types'
import TenderStatus from '@/views/Tender/partials/TenderStatus.vue'
import { ingoingDirection } from '@/constants/capacityDirections'
import EvaluationDetailsHeader from '@/views/Tender/Evaluation/EvaluationDetailsHeader.vue'
import EvaluationOffers from '@/views/Tender/Evaluation/EvaluationOffers.vue'
import { errorMessages } from '@/utils/helpers'

export default Vue.extend({
  name: 'EvaluateBuyingTender',
  components: { EvaluationOffers, EvaluationDetailsHeader, CardTitle, TenderStatus },
  data: function (): {
    id: string
    procurement: TenderWithOffersDTO
    ingoingDirection: string[]
  } {
    const id = 'id' in this.$route.params && this.$route.params.id
    if (!id) {
      throw new Error('EvaluateBuyingTender is missing required route param id')
    }
    return {
      id,
      ingoingDirection,
      procurement: {
        id: 0,
        name: '',
        description: '',
        publishDate: '',
        endDate: '',
        publishTime: '',
        endTime: '',
        type: '',
        status: '',
        lots: [],
      },
    }
  },
  methods: {
    async fetchItem(): Promise<void> {
      const { data } = await TenderService.offers(this.id)
      this.procurement = data.data
    },
    async redirectBack(): Promise<void> {
      await this.$router.push({ name: 'BuyingTenders' })
    },
    updateData(data: TenderWithOffersDTO): void {
      this.procurement = data
    },
    async submit(): Promise<void> {
      const loader = this.$loading.show()
      try {
        const response = await TenderService.complete(this.id)
        if (response) {
          this.$toast.success(response.data.message)
        }
        await this.redirectBack()
      } catch (err: any) { // eslint-disable-line
        if (err.response.data) {
          this.$toast.error(errorMessages(err.response.data.message))
          return
        }
        this.$toast.error(this.$t('somethingWentWrong') as string)
      } finally {
        loader.hide()
      }
    },
  },
  mounted() {
    this.fetchItem()
  },
})
